/* styles.css */

.accordion-open {
    max-height: 1000px; /* Adjust this value based on your content height */
    opacity: 1;
    transition: max-height 0.5s ease-in; 
    /* transition-delay: 0.2s; Delay applied to both opening and closing */
  }
  
  .accordion-close {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-out; 
    /* transition-delay: 0.2s; Delay applied to both opening and closing */
  }
  *{
    transition: 0.5s;
  }
  html{
    scroll-behavior: smooth;
  }

  
  